.image_css {
  cursor: pointer;
  max-width: 100%;
  height: auto;
}

.order_now {
  text-align: center;
  margin-top: -20px;
  display: flex;
  justify-content: space-around;
}

.decide_image img {
  /* cursor: pointer; */
  width: 100%;
}

#decideModal .modal-body {
  padding: 8px !important;
}

#decideModal .close img {
  width: 10px !important;
  height: 10px !important;
  /* margin: 3px 3px 0px 0px; */
}

.orderNowBtn {
  padding: 5px 15px;
  font-size: 16px;
  background-color: #000000;
  color: #fff;
  border: 1px solid #000000;
  border-radius: 7px;
}

.buttonContent {
  display: flex;
  align-items: center;
  gap: 18px; /* Adjust the gap between text and image if needed */
}

.buttonContent img {
  width: 30px;
  height: 30px;
}

.schemeBtn {
  border: none;
  padding: 8px 25px;
  background-image: linear-gradient(
    90deg,
    rgba(255, 243, 166, 1) 0%,
    rgba(249, 223, 123, 1) 0%,
    rgba(221, 183, 78, 1) 0%,
    rgba(181, 126, 16, 1) 0%,
    rgba(238, 194, 93, 1) 0%,
    rgba(235, 188, 92, 1) 41%,
    rgba(225, 170, 58, 1) 95%
  );
}

@media (min-width: 992px) {
  .what_cont .row {
    position: relative;
  }

  .what_cont .col-sm-4:last-child {
    position: absolute;
    right: 0px;
    bottom: 0px;
  }

  .image_css {
    max-width: 100%;
    height: auto;
  }
}

@media (min-width: 320px) and (max-width: 479px) {
  .buttonContent img {
    width: 20px;
    height: 20px;
  }

  .buttonContent {
    font-size: 16px;
  }

  .orderNowBtn {
    padding: 8px 15px;
  }
}
